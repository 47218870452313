<template>
  <b-container fluid>
    <section v-if="isMeetingEnded">
      <div style="align-items: center; display: flex;">
        <font-awesome-icon icon="fa fa-info-circle" /> &nbsp;
        <div v-if="isHost" class="alert-text">{{ $t("OnlineMeetingComponent.HostMeetingCompleted") }}</div>
        <div v-else class="alert-text">{{ $t("OnlineMeetingComponent.GuestMeetingCompleted") }}</div>
      </div>
    </section>
    <section v-else>
      <!-- Odaya iki kişi de katılana kadar herkes bu componentte bekletilir. -->
      <meeting-landing-component ref="landingComponent" v-show="isLandingMode" :is-host="isHost" :meeting-url="meetingUrl" :connections="connections"></meeting-landing-component>

      <div v-show="!isLandingMode" class="row">
        <!-- v-else-if="!meetingNotExists"-->
        <div class="col-md-8">
          <div style="position:relative !important;">
            <div class="col-md-12" style="position:absolute; z-index:11 !important; bottom:5%; display:flex; justify-content:center;">
              <button class="btn btn-icon btn-lg btn-icon-lg btn-light btn-circle" style="margin-right:8px;" @click="changeCameraStatus()">
                <font-awesome-icon v-if="isCameraOpen" icon="fa-solid fa-video" />
                <font-awesome-icon v-else icon="fa-solid fa-video-slash" />
              </button>

              <button class="btn btn-icon btn-lg btn-icon-lg btn-light btn-circle" style="margin-left:8px;" @click="changeMicrophoneStatus()">
                <font-awesome-icon v-if="isMicrophoneOpen" icon="fa-solid fa-microphone" />
                <font-awesome-icon v-else icon="fa-solid fa-microphone-slash" />
              </button>
            </div>
            <div style="right:5%; position:absolute; width:20%; z-index: 10 !important;">
              <video ref="localPlayer" style="width:100% !important; max-height:110px !important;" id="localPlayer" class="video-js" muted autoplay playsinline></video>
            </div>
            <div style="width: 100%; background-color: #000000b8; z-index: 10 !important;">
              <video style="width: 100%; background-color: #000000b8;" ref="remotePlayer" id="remotePlayer" class="video-js" controlsList="" autoplay playsinline />
            </div>
          </div>
          <meeting-chat-component :is-host="isHost" :messages="meeting.chatMessages" :host-name="meeting.hostName" :guest-name="meeting.guestName" ref="chat" />
        </div>
        <div class="col-md-4">
          <iq-card>
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">
                  <font-awesome-icon icon="fa fa-heartbeat" />
                  {{ $t('OnlineMeetingComponent.ConnectionMetrics') }}
                </h4>
              </div>
            </div>
            <div class="iq-card-body">
              <span class="col-md-12">
                {{ $t('OnlineMeetingComponent.ConnectionMetrics_Chat') }}
                <font-awesome-icon v-if="connections.SignalR" class="text-success" icon="fa fa-circle" />
                <font-awesome-icon v-else icon="fa fa-circle" class="text-danger" />
              </span><br />
              <span class="col-md-12">
                {{ $t('OnlineMeetingComponent.ConnectionMetrics_P2P') }}
                <font-awesome-icon v-if="connections.SignalR" class="text-success" icon="fa fa-circle" />
                <font-awesome-icon v-else icon="fa fa-circle" class="text-danger" />
              </span><br /> <hr />
              <span class="col-md-12" style=" display: ruby-text;">
                {{ $t('OnlineMeetingComponent.Info') }} &nsub;
                <font-awesome-icon class="text-warning" icon="fa-sharp fa-thin fa-triangle-exclamation" />
              </span>
            </div>
          </iq-card>
          <meeting-right-component :meeting="meeting"
                                   :is-host="isHost" />
        </div>
      </div>
    </section>
  </b-container>
</template>

<script>

  import videojs from 'video.js/dist/alt/video.core.novtt.min.js'
  import 'video.js/dist/video-js.min.css'
  import 'videojs-record/dist/css/videojs.record.min.css'
  import 'webrtc-adapter'
  import MeetingLandingComponent from './MeetingLandingComponent.vue'
  import meetingChatComponent from './MeetingChatComponent.vue'
  import MeetingRightComponent from './MeetingRightComponent.vue'
  import MeetingService from '../../../services/meeting.js'

  export default {
    name: 'OnlineMeetingHost',
    props: {
      appointmentId: String
    },
    components: {
      MeetingLandingComponent,
      meetingChatComponent,
      MeetingRightComponent
    },
    data: function () {
      return {
        meeting: { id: "", isStarted: false, isEnded: false, chatMessages: [] },
        connections: {
          SignalR: false,
          WebRtc: false,
          LocalCamera: 0
        },
        isSelfStreamStarted: false,
        isRemoteStreamStarted: false,
        localVideo: null,
        localTrack: null,
        recordedChunks: [],
        isMeetingLoading: true,
        isMeetingEnded: false,
        meetingNotExists: true,
        camEnabled: false,
        micEnabled: false,

        isCameraOpen: true,
        isMicrophoneOpen: true,

        localCameraAccess: 0
      }
    },
    destroyed() {
      this.stopLocalStream();
    },
    methods: {
      startStreaming() {
        let self = this;

        navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        }).then(function (stream) {
          self.connections.LocalCamera = 1;
          self.$store.dispatch("Meeting/setMeetingStream", stream);
        }).catch((err) => {
          console.error(err);
          self.connections.LocalCamera = -1;
        });
      },
      changeCameraStatus() {
        if (this.isCameraOpen) {
          this.isCameraOpen = false;
          this.localStream.getVideoTracks()[0].enabled = false;
        }
        else {
          this.isCameraOpen = true;
          this.localStream.getVideoTracks()[0].enabled = true;
        }
      },
      changeMicrophoneStatus() {
        if (this.isMicrophoneOpen) {
          this.isMicrophoneOpen = false;
          this.localStream.getAudioTracks()[0].enabled = false;
        }
        else {
          this.isMicrophoneOpen = true;
          this.localStream.getAudioTracks()[0].enabled = true;
        }
      },
      stopLocalStream() {
        if (this.localStream) {

          if (this.localStream.getVideoTracks().length > 0) {
            this.localStream.getVideoTracks()[0].stop();
            this.localStream.getVideoTracks()[0].enabled = false;
          }

          if (this.localStream.getAudioTracks().length > 0) {
            this.localStream.getAudioTracks()[0].stop();
            this.localStream.getAudioTracks()[0].enabled = false;
          }

          this.isSelfStreamStarted = false;
          this.camEnabled = false;
          this.micEnabled = false;
          this.$store.state.Meeting.simplepeer.destroy();
        }
      },
      endMeeting() {
        let $this = this;
        MeetingService.endMeeting($this.meeting.id)
          .then(response => {
            if (response) {
              $this.stopLocalStream();
              $this.isMeetingEnded = true;

              if ($this.isHost) {
                setTimeout(() => {
                  this.$router.push("/");
                }, 5000);
              }
              $this.$store.state.Meeting.meethub.invoke("EndMeetingToGuest");
            }
          });
      },
      offerP2pConnection() {
        const $this = this;
        $this.$store.state.Meeting.isMeetingStarted = true;
        $this.$store.state.Meeting.meethub.invoke("SendMeetingJoinOfferToGuest");

      },
      acceptP2pConnection() {
        const $this = this;
        $this.$refs.landingComponent.showSpinnerButton = true;
        $this.$store.state.Meeting.meethub.invoke("AcceptMeetingJoinOffer");
      },
      downloadStream() {
        try {
          this.$store.state.Meeting.meetingRecorder.stop();
        } catch (e) {
          console.dir(e);
        }
      },
    },
    computed: {
      isHost: function () { return this.$router.currentRoute.meta.isHost },
      username: function () {
        return (this.isHost == true ? this.meeting.hostName : this.meeting.guestName);
      },
      meetingUrl: function () {
        let url = window.location.origin;
        if (url.includes("https://")) {
          return url + "/joinToMeeting/" + this.appointmentId;
        } else {
          return url.replace("http", "https") + "/joinToMeeting/" + this.appointmentId;
        }
      },
      p2pConnectionEnabled: function () {
        return this.$store.state.Meeting.simplepeer ? this.$store.state.Meeting.simplepeer._connected : false;
      },
      localStream: function () {
        return this.$store.getters['Meeting/meetingStream'];
      },
      isLandingMode: function () {
        return this.$store.state.Meeting.simplepeerConnected == false
      },
      todos: function () {
        return this.$store.state.Meeting.meethub
      }
    },
    mounted: function () {
      let $this = this;

      videojs(this.$refs.localPlayer, this.options, function onPlayerReady() {
        $this.startStreaming();
      })

      videojs(this.$refs.remotePlayer, this.options, function onPlayerReady() {
        console.log('onPlayerReady', this);
      })

      MeetingService.createOrGetByAppointmentId(this.isHost, this.appointmentId)
        .then(response => {
          if (response.data && response.data.meetingNotExists == true) {
            setTimeout(function () { $this.stopLocalStream(); }, 3000);
            $this.isMeetingEnded = true;
          } else {
            if (response.data && response.data.meetingNotExists) {
              $this.meetingNotExists = true;
            }
            else {
              if (response.data.guestCulture) {
                this.$bus.$emit('UiLanguageChanged', response.data.guestCulture);
              }
              $this.meeting = response.data;
              $this.meetingNotExists = false;

              let meetHubUrl = `/meetHub?meetingId=${$this.meeting.id}&isHost=${$this.isHost}&username=${$this.username}`;
              $this.$store.dispatch("Meeting/initializeMeethub", meetHubUrl);
            }
          }
        }).then(function () {
          $this.isMeetingLoading = false;

          if (!$this.isMeetingEnded) {

            $this.$store.state.Meeting.meethub.on('OnRemoteConnected', function () {
              $this.$store.state.Meeting.meethub.invoke("NotifyRemote");
              $this.connections.SignalR = true;
            });

            //Geldiğim odadaki karşı taraftan bir yanıt aldım.
            $this.$store.state.Meeting.meethub.on('OnRemoteNotificationReceived', function () {
              $this.connections.SignalR = true;
            });

            $this.$store.state.Meeting.meethub.on('OnDisconnect', function (connectionId, error) {
              $this.$toastr.error($this.$t("OnlineMeetingComponent.SignalrDisconnectMessage"));

              if (!$this.isHost && $this.$store.state.Meeting.isMeetingStarted && $this.p2pConnectionEnabled) {
                setTimeout(function () {
                  location.reload();
                }, 3000);
              }
              $this.connections.SignalR = false;
              $this.$store.state.Meeting.isMeetingStarted = false;



              if (error != null) {
                console.error(error);
              }

              setTimeout(function () {
                if (window.$.signalR && $this.$store.state.Meeting.meethub.state === window.$.signalR.connectionState.disconnected) {
                  $this.$store.state.Meeting.meethub.start();
                }

              }, 5000);
            });
            $this.$store.state.Meeting.meethub.on('OnTextMessageReceived', function (incomingMessage, translateThis) {
              if (translateThis) {
                incomingMessage.senderName = $this.$t(incomingMessage.senderName)
                incomingMessage.message = $this.$t(incomingMessage.message)
                $this.meeting.chatMessages.push(incomingMessage);
              }
              else
                $this.meeting.chatMessages.push(incomingMessage);

              if (document.getElementById("chatMessages")) {
                setTimeout(function () { document.getElementById("chatMessages").scrollTop = document.getElementById("chatMessages").scrollHeight; }, 200);
              }
            });

            $this.$store.state.Meeting.meethub.on('OnFileReceived', function () {
              $this.$bus.$emit('RefreshMultimediaList');
            });

            //Bu metod sadece guest için çalışacaktır.
            $this.$store.state.Meeting.meethub.on('MeetingJoinOfferReceived', function () {
              $this.$store.state.Meeting.isMeetingStarted = true;
              if ($this.p2pConnectionEnabled == false) {
                $this.$store.dispatch('Meeting/initializeSimplepeer', $this.isHost);
              }
            });

            //Bu metod sadece guest için çalışacaktır.
            $this.$store.state.Meeting.meethub.on('MeetingOfferEnded', function () {
              $this.stopLocalStream();
              $this.isMeetingEnded = true;
            });

            //Bu metod sadece host için çalışacaktır.
            $this.$store.state.Meeting.meethub.on('MeetingJoinOfferAccepted', function () {
              //toplantının başladığı noktadır.
              if ($this.p2pConnectionEnabled == false) {
                $this.$store.dispatch('Meeting/initializeSimplepeer', $this.isHost);
              }
            });

            $this.$store.state.Meeting.meethub.on('OnSignalReceived', function (incomingSignal) {

              if (!$this.$store.state.Meeting.simplepeer) {
                $this.offerP2pConnection();
              }

              //if ($this.isHost && $this.p2pConnectionEnabled == false) {
              if ($this.p2pConnectionEnabled == false) {
                setTimeout(function () { $this.$store.state.Meeting.simplepeer.signal(JSON.parse(incomingSignal)); }, 200);
              }

            });

            $this.$store.state.Meeting.meethub.start().then(() => {

              if ($this.isHost == true) {
                $this.$store.state.Meeting.meethub.invoke("OnHostCreated", $this.appointmentId)
              }
              else {
                $this.$store.state.Meeting.meethub.invoke("OnGuestCreated", $this.appointmentId)
              }
            })
              .catch(() => {
                setTimeout(function () { $this.$store.state.Meeting.meethub.start(); }, 1500);
              });

            setTimeout(function () { console.log($this.$store.state.Meeting.meethub._connectionStarted); }, 5000);

          }

        });
    }
  }
</script>

<style>
  .remotePlayer-dimensions {
    height: 480px;
  }
</style>
